import * as React from "react"
import axios from "axios"
import KitchenSettingsMainInCont from "./kitchenSettingsMainInCont"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "55%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const KitchenSettingsMain = ({ user, dispatchFeedback }) => {
  const [loading, setLoading] = React.useState(true)
  const [kitchenSettings, setKitchenSettings] = React.useState({})
  const [sections, setSections] = React.useState([])

  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(process.env.GATSBY_STRAPI_URL + `/api/kitchen-setting`, {
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
      })
      .then(response => {
        setKitchenSettings({ ...response.data.data })
        axios
          .get(process.env.GATSBY_STRAPI_URL + `/api/sections`, {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          })
          .then(res => {
            setLoading(false)
            let sectionsCopy = [...res.data.data]
            sectionsCopy.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })

            setSections(sectionsCopy)
          })
          .catch(err => {
            console.error(err)
            setLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }, [dispatchFeedback, user.jwt])

  return (
    <>
      {loading || Object.keys(kitchenSettings).length < 1 ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      ) : (
        <KitchenSettingsMainInCont
          user={user}
          kitchenSettings={kitchenSettings}
          setKitchenSettings={setKitchenSettings}
          sections={sections}
          setSections={setSections}
          dispatchFeedback={dispatchFeedback}
        />
      )}
    </>
  )
}

export default KitchenSettingsMain
